import { ReactNode, useState } from "react"
import cn from "classnames"

import iconSrc from "assets/images/carousel-arrow.webp"
import "./Carousel.scss"

type Props = {
  children: ReactNode[]
  offsetTop?: number
}

export const Carousel = ({ children, offsetTop = 0 }: Props): JSX.Element => {
  const newChildren = children.length > 2 ? children : children.concat(children)
  const [active, setActive] = useState<number | undefined>(undefined)

  const onNextClick = () => {
    if (active !== undefined) {
      if (active === newChildren.length - 1) {
        setActive(0)
      } else {
        setActive(active + 1)
      }
    } else {
      setActive(1)
    }
  }

  const onPrevClick = () => {
    if (active !== undefined) {
      if (active === 0) {
        setActive(newChildren.length - 1)
      } else {
        setActive(active - 1)
      }
    } else {
      setActive(newChildren.length - 1)
    }
  }

  return (
    <div className="carousel-wrapper">
      <button
        onClick={onPrevClick}
        type="button"
        className="carousel-wrapper-btn"
        style={{
          marginTop: `${offsetTop}px`,
        }}
      >
        <img src={iconSrc} alt="arrow" />
      </button>
      <div className="carousel-items-wrapper">
        {newChildren.map((item, index) => (
          <div
            className={cn("carousel-item", {
              active: active === index,
              next:
                active === undefined
                  ? index === 1
                  : active === newChildren.length - 1
                  ? index === 0
                  : active + 1 === index,
              nextNext:
                active === undefined
                  ? index === 2
                  : active === newChildren.length - 2
                  ? index === 0
                  : active + 2 === index,
              prev:
                active === undefined
                  ? index === newChildren.length - 1
                  : active === 0
                  ? index === newChildren.length - 1
                  : active - 1 === index,
              prevPrev:
                active === undefined
                  ? index === newChildren.length - 2
                  : active === 0
                  ? index === newChildren.length - 2
                  : active - 2 === index,
              initial: index === 0 && active === undefined,
            })}
            key={index}
          >
            {item}
          </div>
        ))}
      </div>
      <button
        onClick={onNextClick}
        type="button"
        className="carousel-wrapper-btn"
        style={{
          marginTop: `${offsetTop}px`,
        }}
      >
        <img src={iconSrc} alt="arrow" className="carousel-icon-right" />
      </button>
    </div>
  )
}
