import React from "react"

import termsSrc from "assets/pdfs/terms.pdf"

import "./Terms.scss"

export const Terms = (): JSX.Element => {
  return (
    <div className="privacy">
      <embed src={termsSrc} />
    </div>
  )
}
