import React from "react"

import pdfSrc from "assets/pdfs/description.pdf"

import "./Description.scss"

export const Description = (): JSX.Element => {
  return (
    <div className="description">
      <embed src={pdfSrc} />
    </div>
  )
}
