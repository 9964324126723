import { useNavigate } from "react-router"
import {useMedia} from "use-media";
import { Link } from "react-router-dom"

import { routes, scrollToElement} from "utils"

import "./Specs.scss"
import { ReactComponent as Arrow } from "assets/icons/collapse-arrow.svg"
import { ReactComponent as File } from "assets/icons/file.svg"
import pdfSrc from "assets/pdfs/description.pdf"

export const Specs = (): JSX.Element => {
  const isM = useMedia({ maxWidth: 1200 })
  const navigate = useNavigate()

  return (
    <div className="specs" id="specs">
      <h2 className="specs-title">Specs</h2>
      {isM ? (
        <a download href={pdfSrc} className="footer-link">
          <File />
          White Paper
        </a>
      ) : (
        <Link className="footer-link" to={routes.description}>
          <File />
          White Paper
        </Link>
      )}
      <div className="specs-content">
        <p className="specs-content-desc">
          The Fight Zone Championship is a unique professional boxing promotion fueled by fan-driven energy and the
          first ever boxing organization moving to a caged platform.
          <br />
          <br />
          Join the Fight Zone Championship first Soul Bound NFT release to have your say in how the league grows and
          operates while enjoying lifetime privileges.
        </p>
        <p className="specs-content-desc last">
          Fight Zone Championship was created to provide real benefits for its athletes and the fans.
          <br />
          <br />
          Our VIP Fight weekend experience features luxury accommodations along with VIP Cage Side seats providing you
          the best possible view of the fights that you helped make happen!
          <br />
          <br />
          All of this and more perks and utilities will be available to our Fight Zone NFT Collection holders.
        </p>
      </div>
      <button
        type="button"
        className="specs-btn"
        onClick={() => {
          navigate(`/?id=letter`)
          scrollToElement("letter")
        }}
      >
        Read letter from the founder
        <Arrow />
      </button>
    </div>
  )
}
