import React from "react"

import termsSrc from "assets/pdfs/privacy-policy.pdf"

import "./Privacy.scss"

export const Privacy = (): JSX.Element => {
  return (
    <div className="privacy">
      <embed src={termsSrc} />
    </div>
  )
}
