import { useState } from "react"
import { useMedia } from "use-media"

import { mixins } from "utils"

import letterSrc from "assets/images/letter.webp"
import letterAuthorSrc from "assets/images/team/1.webp"
import { ReactComponent as Sign } from "assets/icons/signature.svg"
import "./Letter.scss"
import cn from "classnames"

export const Letter = (): JSX.Element => {
  const isM = useMedia({ maxWidth: mixins.m })
  const [show, setShow] = useState(false)

  return (
    <div className="letter" id="letter">
      <img alt="letter" src={letterSrc} className="letter-bg" />
      <h2 className="letter-title">Letter From the founder</h2>
      <div
        className={cn("letter-content", {
          hide: isM && !show,
        })}
      >
        <h4 className="letter-content-title">Welcome to the Fight Zone!</h4>
        <p className="letter-content-desc">
          It is a pleasure to introduce you to the FIGHT ZONE CHAMPIONSHIP (“FZC”) ™ for your NFT portfolio
          consideration. Combat sports in one form or another have existed around the globe for ages but it was only
          truly monetized here in the United States several decades ago. While the passion for the “traditional” combat
          sports in the United States has ebbed and flowed, there can be no doubt that passion is increasing again at a
          feverish pace! There are three (3) principle reasons for the renewed interest: <br />
          -The sport, as traditionally constituted, has grown stale in terms of presentation and innovation allowing
          new, young creative thinkers to forcefully engage with new ideas from presentation, to distribution, to retail
          applications;
          <br />
          -In conjunction, technological innovation has accelerated a reimagining of the supply and value chain of the
          sport and new distribution opportunities and with them corresponding new revenue streams are clearly on the
          horizon;
          <br />
          -The egalitarian nature of new technologies has enhanced fighter awareness and engagement, introducing a
          burgeoning era of new thinking, new expectations, new consumers and an enhanced interest by the fighters
          themselves to reimagine the combat sport industry. Therefore, all traditional assumptions supporting the
          “conventional” thinking around combat sports are being thrown out the window. Which is where FZC comes into
          play.
        </p>
        <p className="letter-content-desc">
          It starts with content! The old adage, “content is king” is still very much applicable today. Traditionally,
          given technological limitations, the control of content rested in a few hands. But the advent of digital
          technology and its myriad applications has changed the landscape in every sport, including combat sports.
          Athlete’s now have more control over their branding, marketing and licensing which, in turn, has given them
          more power at the negotiating table. It is this new awakening and the conversations that have ensued with all
          of the relevant principles, including and ESPECIALLY the athletes, that have birthed the FIGHT ZONE
          CHAMPIONSHIP™!!!
        </p>
        <p className="letter-content-desc">
          FIGHT ZONE CHAMPIONSHIP ™ is a “FAN POWERED/FIGHTER FIRST LEAGUE” ™ whose emphasis is on the immersive fan
          experience. Period!! The sport of boxing has been perpetually tainted by the politics of uber promoters,
          sanctioning body secrecy and match control. The FIGHT ZONE CHAMPIONSHIP NFT and the “HARD CARD” ™ gives YOU,
          the fan, the power to change this narrative forever! How?? By giving you and your community of FZC/NFT peers a
          vote on preferred matches! No more longing to see head to head matches which never transpire and no more
          denying the boxers the opportunity to compete against the opponents they know YOU want to see them fight!
        </p>
        <p className="letter-content-desc">
          The FIGHT ZONE CHAMPIONSHIP ™ AND NFT COLLECTION gives YOU the power to propel the league forward by
          harnessing the ENERGY and EXCITEMENT we all feel when watching a combat sport event by providing you real
          influence on how the league operates.
        </p>
        <p className="letter-content-desc">
          The FIGHT ZONE CHAMPIONSHIP NFT COLLECTION ™ isn’t some ephemeral concept where assets have no more value then
          vapor. No! The holders of the FIGHT ZONE CHAMPIONSHIP NFT’s ™ represent the future of sports ushering in real
          change, with real value, based on enhanced emotional fan engagement and combat sports competition! As your
          diamond encrusted “Hard Card” ™ that accompanies your NFT says, “FIGHT ZONE CHAMPIONSHIP, FAN POWERED AND
          FIGHTER DELIVERED”.™
        </p>
        <div className="letter-content-footer">
          <p className="letter-content-footer-text">
            <img src={letterAuthorSrc} alt="men" className="letter-content-footer-text-img" />
            See you at the fights! Oh, and don’t forget your “Hard Card”™! - Jack Perone Fulton, <br /> Founder & CEO
          </p>
          <Sign />
        </div>
      </div>
      {isM && !show && (
        <button className="letter-content-btn" onClick={() => setShow(true)} type="button">
          Show more
        </button>
      )}
    </div>
  )
}
