import { useRef, useState } from "react"

import { useClickOutside } from "utils"

import { ReactComponent as Close } from "assets/icons/close-modal.svg"
import "./VideoModal.scss"
import videoSrc from "assets/videos/fightzone.mp4"

type Props = {
  onClose: () => void
}

export const VideoModal = ({ onClose }: Props): JSX.Element => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  useClickOutside(wrapperRef, () => {
    onClose()
  })

  return (
    <div className="mint-modal-wrapper">
      <div ref={wrapperRef} className="mint-modal-content animate__animated animate__zoomIn animate__faster">
        <Close className="mint-modal-content-icon" onClick={onClose} />
        <video controls className="mint-modal-content-video">
          <source src={videoSrc} />
        </video>
      </div>
    </div>
  )
}
