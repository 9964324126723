import koSrc from "assets/images/ko.webp"
import icbMobileSrc from "assets/images/specs.webp"
import "./About.scss"
import { useMedia } from "use-media"
import { mixins } from "utils"

export const About = (): JSX.Element => {
  const isM = useMedia({ maxWidth: mixins.m })

  return (
    <div className="about" id="home">
      <div className="about-content">
        <div className="about-left">
          <h1>Fight Zone</h1>
          <h2>Championship </h2>
          <h3>NFT Collection</h3>
        </div>
        <div className="about-right">
          <p className="about-right-desc">New Era of hexagon cage boxing and fighting. Revolutionary events.</p>
          <p className="about-right-sub">Powered by Web3 for a next-level boxing event experience</p>
        </div>
      </div>
      {isM ? (
        <div className="about-footer-mobile">
          <img src={koSrc} alt="ko" className="about-footer-mobile-ko" />
          <div className="about-footer-mobile-text">Presented by</div>
          <img src={koSrc} alt="ko" className="about-footer-mobile-ko" />
        </div>
      ) : (
        <img src={icbMobileSrc} alt="about" className="about-bg" />
      )}
    </div>
  )
}
