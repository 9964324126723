import { Link } from "react-router-dom"
import { useMedia } from "use-media"

import { hrefs, mixins, routes } from "utils"

import unicornSrc from "assets/images/partners/11.webp"
import icbSrc from "assets/images/ko.webp"
import { ReactComponent as Facebook } from "assets/icons/facebook.svg"
import { ReactComponent as Twitter } from "assets/icons/twitter.svg"
import { ReactComponent as Youtube } from "assets/icons/youtube.svg"
import { ReactComponent as Discord } from "assets/icons/discord.svg"
import { ReactComponent as Instagram } from "assets/icons/instagram.svg"
import "./Footer.scss"

export const Footer = (): JSX.Element => {
  const isM = useMedia({ maxWidth: mixins.m })
  const links = [
    {
      icon: <Discord />,
      href: hrefs.discord,
    },
    {
      icon: <Instagram />,
      href: hrefs.instagram,
    },
    {
      icon: <Youtube />,
      href: hrefs.youtube,
    },
    {
      icon: <Facebook />,
      href: hrefs.facebook,
    },
    {
      icon: <Twitter />,
      href: hrefs.twitter,
    },
  ]

  return (
    <footer className="footer" id="contacts">
      <div className="footer-title">The action starts now!</div>
      <div className="footer-desc">Follow Fight Zone Championship on:</div>
      <div className="footer-links">
        {links.map((link, index) => (
          <a key={index} className="footer-links-item" href={link.href} target="_blank" rel="noreferrer">
            {link.icon}
          </a>
        ))}
      </div>
      <div className="footer-sub">“Fan Powered Fighter Delivered”</div>
      <div className="footer-bottom">
        {!isM && (
          <div className="footer-bottom-date">
            ©{new Date().getFullYear()} International Championship Boxing, LLC
            <br />
            ©{new Date().getFullYear()} Pink Unicorn LTD
          </div>
        )}
        <div className="footer-bottom-connect">
          <a
            className="footer-bottom-connect-mail"
            target="_blank"
            rel="noreferrer"
            href="mailto:support@pinkunicorn.com"
          >
            support@pinkunicorn.com
          </a>
          <a
            className="footer-bottom-connect-mail"
            target="_blank"
            rel="noreferrer"
            href="mailto:support@fightzone.com"
          >
            support@fightzone.com
          </a>
        </div>
        <div className="footer-bottom-powered">
          <img src={icbSrc} alt="icb" className="footer-bottom-powered-icb" />
          <div className="footer-bottom-powered-unicorn">
            <img src={unicornSrc} alt="unicorn" className="footer-bottom-powered-unicorn-img" />
            <div className="footer-bottom-powered-unicorn-info">
              Powered by
              <a
                className="footer-bottom-powered-unicorn-info-link"
                target="_blank"
                rel="noreferrer"
                href={hrefs.unicorn}
              >
                Pink Unicorn
              </a>
            </div>
          </div>
        </div>
        {isM && (
          <div className="footer-bottom-date">
            ©{new Date().getFullYear()} International Championship Boxing, LLC
            <br />
            ©{new Date().getFullYear()} Pink Unicorn LTD
          </div>
        )}
      </div>
      <div className="footer-rights">
        <div className="footer-rights-left">All Rights Reserved .</div>
        <div className="footer-rights-right">
          <Link to={routes.privacy} className="footer-rights-right-link">
            Privacy Policy
          </Link>
          <Link to={routes.terms} className="footer-rights-right-link">
            Terms & Conditions
          </Link>
        </div>
      </div>
    </footer>
  )
}
