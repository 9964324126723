import { useNavigate } from "react-router"
import cn from "classnames"
import { useState } from "react"

import { scrollToElement } from "utils"

import { ReactComponent as Logo } from "assets/icons/logo.svg"
import { ReactComponent as Close } from "assets/icons/close.svg"
import { ReactComponent as Burger } from "assets/icons/menu.svg"
import "./Header.scss"
import { useSearchParams } from "react-router-dom"

export const Header = (): JSX.Element => {
  const navigate = useNavigate()
  const [sidebar, setSidebar] = useState(false)
  const [search] = useSearchParams()

  const toggleSidebar = () => {
    setSidebar(!sidebar)
  }

  const links = [
    {
      title: "Home",
      id: "home",
    },
    {
      title: "Specs",
      id: "specs",
    },
    {
      title: "Collection",
      id: "collection",
    },
    {
      title: "Team",
      id: "team",
    },
    {
      title: "Letter",
      id: "letter",
    },
    {
      title: "Contacts",
      id: "contacts",
    },
  ]

  return (
    <header className={cn("header")}>
      <div className="header-mobile">
        <Logo className="header-mobile-logo" />
        <button type="button" onClick={toggleSidebar} className="header-mobile-btn">
          {sidebar ? <Close /> : <Burger />}
        </button>
        <div
          className={cn("header-mobile-sidebar", {
            active: sidebar,
          })}
        >
          <div className="header-links">
            {links.map((link, index) => (
              <button
                type="button"
                onClick={() => {
                  navigate(`/?id=${link.id}`)
                  scrollToElement(link.id)
                  setSidebar(false)
                }}
                className={cn("header-links-item", {
                  active: search.get("id") === link.id,
                })}
                key={index}
              >
                {link.title}
              </button>
            ))}
          </div>
        </div>
      </div>
      <div className="header-desktop">
        <Logo className="header-logo" />
        <div className="header-links">
          {links.map((link, index) => (
            <button
              type="button"
              onClick={() => {
                navigate(`/?id=${link.id}`)
                scrollToElement(link.id)
              }}
              className="header-links-item"
              key={index}
            >
              {link.title}
            </button>
          ))}
        </div>
      </div>
    </header>
  )
}
