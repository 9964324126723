import cn from "classnames"
import { useMedia } from "use-media"
import { useState } from "react"
import { Link } from "react-router-dom"

import { mixins, routes } from "utils"
import { Collapse } from "components"

import { ReactComponent as File } from "assets/icons/file.svg"
import "./CardInfo.scss"

export type ICard = {
  title: string
  img: string
  perks: string[]
  offers: number
  link: string
  id: string
  beforePerk: string
}

type Props = {
  card: ICard
  black?: boolean
  withFile?: boolean
}

export const CardInfo = ({ card, black, withFile }: Props): JSX.Element => {
  const [loading, setLoading] = useState(true)
  const isM = useMedia({ maxWidth: mixins.m })

  return (
    <div
      className={cn("card", {
        black: black,
      })}
      id={card.id}
    >
      <div className="card-info">
        <h3 className="card-info-title">
          {card.title} <span>card</span>
        </h3>
        {/*<div className="card-info-before-perk">{card.beforePerk}</div>*/}
        {/*{withFile && (*/}
        {/*  <Link className="card-info-link" to={routes.blackDiamond}>*/}
        {/*    <File />*/}
        {/*    Black Diamond Card SAFE Agreement*/}
        {/*  </Link>*/}
        {/*)}*/}
        {isM ? (
          <Collapse title="Perks">
            <ul className="card-info-list">
              {card.perks.map((perk, index) => (
                <li className="card-info-list-item" key={index}>
                  {perk}
                </li>
              ))}
            </ul>
          </Collapse>
        ) : (
          <ul className="card-info-list">
            Perks:
            {card.perks.map((perk, index) => (
              <li className="card-info-list-item" key={index}>
                {perk}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="card-preview">
        <div className="card-preview-wrapper">
          {loading && (
            <div className="card-preview-loading">
              <div className="spinner-fast">
                <div className="spinner-fast">
                  <div className="spinner-fast">
                    <div className="spinner-fast"></div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <img onLoad={() => setLoading(false)} src={card.img} alt="card preview" className="card-preview-img" />
        </div>
        <a className="card-preview-link" href={card.link} target="_blank" rel="noreferrer">
          Coming Soon
        </a>
        <div className="card-preview-offers">{card.offers} offerings</div>
      </div>
    </div>
  )
}
