import React, { ReactNode, useRef, useState } from "react"
import cn from "classnames"

import { ReactComponent as ArrowIcon } from "assets/icons/collapse-arrow.svg"
import "./Collapse.scss"

type Props = {
  children: ReactNode
  title: ReactNode
  height?: number
}

export const Collapse = ({ children, title, height = 45 }: Props): JSX.Element => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div
      ref={wrapperRef}
      style={{ height: isOpen ? wrapperRef?.current?.scrollHeight : height }}
      className={cn("collapse", {
        open: isOpen,
      })}
    >
      <button onClick={toggleOpen} type="button" className="collapse-title">
        {title}
        <div className="collapse-buttons">
          <div onClick={toggleOpen} className="collapse-arrow">
            <ArrowIcon />
          </div>
        </div>
      </button>
      <div className="collapse-content">{children}</div>
    </div>
  )
}
