import { ICard } from "components"
import diamondSrc from "assets/gifs/diamond.gif"
import platinumSrc from "assets/gifs/platinum.gif"
import sapphireSrc from "assets/gifs/sapphire.gif"
import goldSrc from "assets/gifs/gold.gif"
import silverSrc from "assets/gifs/silver.gif"

export const cards: ICard[] = [
  {
    title: "Black Diamond",
    img: diamondSrc,
    perks: [
      "2 VIP Cage Side seats for all Fight Zone Championship events (Lifetime)",
      "All-access for Cardholder to weigh-ins and media events, and all-access (plus one) to all VIP after parties",
      "Limo service from hotel to venue for all events (25-mile radius)",
      "1 comped hotel room for all events (queen or two doubles)",
      "Discounted hotel accommodations for up to 3 additional guests",
      "Shot of the Night fight posters (Exclusive action digital image of the contest)",
      "1 vote toward league-considered rematches (motivated by questionable judge scoring. Will be titled Redemption Events)",
      "2 Pound for Pound best boxer in the world votes",
      "Discounted access to Fight Zone Championship Pay Per View and Streaming platforms",
      "Free new merchandise drops for two, plus 25% off purchases",
      "1 Future Fight Zone Championship Hall of Fame vote",
      "Free Advertising for your business in the Metaverse",
      "Concierge Travel Membership",
    ],
    beforePerk: "150,000 Class B shares at $150,000,000.00 valuation",
    link: "",
    offers: 100,
    id: "diamond",
  },
  {
    title: "Platinum",
    img: platinumSrc,
    perks: [
      "2 second tier cage side floor seating for all Fight Zone Championship events (Lifetime)",
      "Access to all VIP after parties, weigh-ins, and media events for Сardholder and 1 guest",
      "Discounted hotel accommodations for Сardholder and 1 guest",
      "Shot of the night fight posters (Exclusive action digital image of the contest)",
      "Discounted access to Fight Zone Championship Pay Per View and Streaming platforms",
      "25% off all Fight Zone Championship merchandise",
      "“Pound for Pound” best boxer in the world vote",
      "Concierge Travel Membership",
    ],
    link: "",
    beforePerk: "30,000 Class C shares at a valuation to be determined \n1 month before the drop",
    offers: 500,
    id: "platinum",
  },
  {
    title: "Sapphire",
    img: sapphireSrc,
    perks: [
      "2 first level tickets to one Fight Zone Championship Events annually",
      "Access to all VIP after parties, weigh-ins, media events for Sapphire cardholder and 1 guest",
      "Discounted hotel accommodations for Sapphire cardholder and 1 guest",
      "Discounted access to Fight Zone Championship Pay Per View and Streaming platforms",
      "25% off all Fight Zone Championship merchandise",
      "“Pound for Pound” best boxer in the world vote",
      "Concierge Travel Membership",
    ],
    beforePerk: "15,000 class D shares at a valuation to be determined 1 month before the drop",
    link: "",
    offers: 1000,
    id: "sapphire",
  },
  {
    title: "Gold",
    img: goldSrc,
    perks: [
      "2 general admission tickets to one Fight Zone Championship Events annually",
      "Access to 2 VIP after parties, all weigh-ins and media events for Gold cardholder and 1 guest annually",
      "Discounted hotel accommodations for Gold cardholder and 1 guest",
      "Discounted access to Fight Zone Championship Pay Per View and Streaming platforms",
      "25% off all Fight Zone Championship merchandise",
      "“Pound for Pound” best boxer in the world vote",
      "Concierge Travel Membership",
    ],
    beforePerk: "3,000 class E shares at a valuation to be determined 1 month before the drop",
    link: "",
    offers: 5000,
    id: "gold",
  },
  {
    title: "Silver",
    img: silverSrc,
    perks: [
      "2 general admission tickets to one Fight Zone Championship Event annually",
      "Access to 1 VIP after parties for Silver cardholder and 1 guest annually",
      "Discounted access to Fight Zone Championship Pay Per View and Streaming platforms",
      "25% off all Fight Zone Championship merchandise",
      "“Pound for Pound” best boxer in the world vote",
      "Concierge Travel Membership",
    ],
    beforePerk: "1,500 Class F shares at a valuation to be determined 1 month before the drop",
    link: "",
    offers: 10000,
    id: "silver",
  },
]
