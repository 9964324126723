import { useNavigate } from "react-router"

import { ReactComponent as Bg } from "assets/icons/team-bg.svg"
import diamondSrc from "assets/images/cards/diamond.webp"
import silverSrc from "assets/images/cards/silver.webp"
import sapphireSrc from "assets/images/cards/sapphire.webp"
import goldSrc from "assets/images/cards/gold.webp"
import platinumSrc from "assets/images/cards/platinum.webp"
import "./Collection.scss"
import { Carousel } from "../Carousel"
import { scrollToElement } from "utils"

export const Collection = (): JSX.Element => {
  const navigate = useNavigate()
  const cards = [
    {
      img: sapphireSrc,
      id: "sapphire",
    },
    {
      img: goldSrc,
      id: "gold",
    },
    {
      img: diamondSrc,
      id: "diamond",
    },
    { img: silverSrc, id: "silver" },
    {
      img: platinumSrc,
      id: "platinum",
    },
  ]

  return (
    <div className="collection" id="collection">
      <Bg className="collection-bg" />
      <h2 className="collection-title">The Collection</h2>
      <p className="collection-desc">
        Fight Zone NFT Collection is a unique collection of cards loaded with <br /> possibilities. Its goal is to give
        the holders real decision-making power and <br /> other utilities topped off with high-end perks. A combination
        never seen <br /> before in entertainment, sports, or blockchain.
      </p>
      <div className="collection-content">
        <Carousel>
          {cards.map((card, index) => (
            <button
              onClick={() => {
                navigate(`/?id=${card.id}`)
                scrollToElement(card.id)
              }}
              key={index}
              className="collection-content-item"
              type="button"
            >
              <img src={card.img} alt="card" className="collection-content-item-img" />
            </button>
          ))}
        </Carousel>
      </div>
    </div>
  )
}
