import React, { useEffect, useRef } from "react"
import cn from "classnames"

import { useClickOutside } from "utils"

import { ReactComponent as Close } from "assets/icons/close.svg"
import "./Modal.scss"

type Props = {
  isOpen: boolean
  onClose: () => void
  item: any
}

export const Modal = ({ isOpen, onClose, item }: Props): JSX.Element | null => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const body = document.body

  useEffect(() => {
    if (isOpen) {
      body.style.overflow = "hidden"
    }
  }, [isOpen, body.style.overflow])

  const handleClose = () => {
    body.style.overflow = "scroll"
    onClose()
  }

  useClickOutside(wrapperRef, () => {
    handleClose()
  })

  useEffect(() => {
    document.addEventListener("keydown", e => {
      if (e.key === "Escape" && isOpen) {
        onClose()
      }
    })
    return () => {
      document.removeEventListener("keydown", e => e)
    }
  }, [isOpen, onClose])

  if (!isOpen) {
    body.style.overflow = "scroll"

    return null
  }

  return (
    <div className={cn("modal")}>
      <div className="modal-backdrop" />
      <div ref={wrapperRef} style={{ maxWidth: 824 }} className={cn("modal-content", {})}>
        <button className="modal-close" type="button" onClick={handleClose}>
          <Close />
        </button>
        <div className="modal-body">
          <img src={item.img} alt="team img" className="modal-body-img" />
          <div className="modal-body-info">
            <div className="modal-body-info-title">{item.title}</div>
            <div className="modal-body-info-position">{item.position}</div>
            <div className="modal-body-info-desc">{item.desc}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
