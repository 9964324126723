import { useSearchParams } from "react-router-dom"
import { useEffect, useRef, useState } from "react"

import "./Home.scss"
import videoSrc from "assets/videos/fightzone.mp4"
import { About, Header, Specs, Team, Footer, Collection, CardInfo, Partners, Letter } from "components"
import { cards } from "./cards"
import { scrollToElement } from "utils"
import { ReactComponent as PauseIcon } from "assets/icons/pause.svg"
import { ReactComponent as PlayIcon } from "assets/icons/play.svg"
import { ReactComponent as OpenIcon } from "assets/icons/maximize-circle.svg"
import { VideoModal } from "../../modals"

export const Home = (): JSX.Element => {
  const wrapperRef = useRef<any>(null)
  const [loaded, setLoaded] = useState(false)
  const [modal, setModal] = useState(false)
  const [isPlaying, setIsPlaying] = useState(true)
  const [search] = useSearchParams()

  useEffect(() => {
    console.log("hi")
    const id = search.get("id")
    if (id) {
      scrollToElement(id)
    }
  }, [search])

  function isOdd(num) {
    return num % 2
  }

  const onPause = () => {
    setIsPlaying(true)
  }

  const onPlay = () => {
    setIsPlaying(false)
  }

  return (
    <div className="home">
      <video ref={wrapperRef} muted={isPlaying} className="home-video" onLoadedData={() => setLoaded(false)} autoPlay>
        <source src={videoSrc} />
      </video>
      <div className="player-wrapper">
        <div className="player-content">
          {isPlaying ? (
            <button className="player-btn" type="button" onClick={onPlay}>
              <PlayIcon />
            </button>
          ) : (
            <button className="player-btn" type="button" onClick={onPause}>
              <PauseIcon />
            </button>
          )}
          <button className="player-btn" type="button" onClick={() => setModal(true)}>
            <OpenIcon />
          </button>
        </div>
      </div>
      <Header />
      <About />
      <Specs />
      <Collection />
      {cards.map((card, index) => (
        <CardInfo withFile={index === 0} card={card} key={index} black={!!isOdd(index)} />
      ))}
      <Team />
      <Letter />
      <Partners />
      <Footer />
      {modal && <VideoModal onClose={() => setModal(false)} />}
    </div>
  )
}
