import { BrowserRouter, Route, Routes } from "react-router-dom"

import { BlackDiamond, Description, Home, Privacy, Terms } from "pages"
import { routes } from "utils"

export const Router = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes.index} element={<Home />} />
        <Route path={routes.privacy} element={<Privacy />} />
        <Route path={routes.terms} element={<Terms />} />
        <Route path={routes.blackDiamond} element={<BlackDiamond />} />
        <Route path={routes.description} element={<Description />} />
      </Routes>
    </BrowserRouter>
  )
}
