import partners1Src from "assets/images/partners/1.webp"
import partners2Src from "assets/images/partners/2.webp"
import partners3Src from "assets/images/partners/3.webp"
import partners4Src from "assets/images/partners/4.webp"
import partners5Src from "assets/images/partners/5.webp"
import partners6Src from "assets/images/partners/6.webp"
import partners7Src from "assets/images/partners/7.webp"
import partners8Src from "assets/images/partners/8.webp"
import partners9Src from "assets/images/partners/9.webp"
import partners10Src from "assets/images/partners/10.webp"
import partners11Src from "assets/images/partners/12.webp"
import partners12Src from "assets/images/partners/11.webp"
import partners13Src from "assets/images/partners/13.webp"
import partners14Src from "assets/images/partners/14.webp"
import partners15Src from "assets/images/partners/15.webp"
import { ReactComponent as Arrow } from "assets/icons/arrow.svg"
import "./Partners.scss"
import cn from "classnames"
import { useMedia } from "use-media"
import { useEffect, useState } from "react"
import { mixins } from "../../utils"

export const Partners = (): JSX.Element => {
  const [translateX, setTranslateX] = useState(0)
  const [goNext, setGoNext] = useState(true)
  const isS = useMedia({ maxWidth: mixins.m })

  const partners = [
    {
      link: "https://playcaller.io/app/",
      img: partners11Src,
    },
    {
      link: "https://www.buenavistavisions.com/",
      img: partners10Src,
    },
    {
      link: "https://friendsofthemetaverse.com/",
      img: partners9Src,
    },
    {
      link: "https://www.ergodyne.com/",
      img: partners1Src,
    },
    {
      link: "https://www.pinkunicornlabs.com/",
      img: partners12Src,
    },
    {
      link: "https://www.statewaterheaters.com/",
      img: partners2Src,
    },
    {
      isWhite: true,
      link: "https://www.zukermanassoc.com/",
      img: partners14Src,
    },
    {
      link: "https://www.farmers.com/",
      img: partners13Src,
    },
    {
      isWhite: true,
      link: "https://www.danuvaclothing.com/",
      img: partners15Src,
    },
    {
      link: "https://arrowheadbrass.com/",
      img: partners3Src,
    },
    {
      link: "https://www.rockyboots.com/",
      img: partners4Src,
    },
    {
      link: "https://daedong-kioti.com/",
      img: partners5Src,
    },
    {
      link: "https://jonwayne.com/",
      img: partners6Src,
    },
    {
      link: "https://itcstpete.com/",
      img: partners7Src,
    },
    {
      link: "https://signaturelendingservices.com/",
      img: partners8Src,
    },
  ]

  const onNextClick = () => {
    setTranslateX(translateX - 220)
  }

  const onPrevClick = () => {
    setTranslateX(translateX + 220)
  }

  const nextInterval = () => {
    if (isS ? translateX === -3080 : translateX === -2640) {
      return setGoNext(false)
    }
    return setTranslateX(translateX - 220)
  }
  const prevInterval = () => {
    if (translateX === 0) {
      return setGoNext(true)
    }
    return setTranslateX(translateX + 220)
  }

  useEffect(() => {
    if (!goNext) {
      const id = setInterval(prevInterval, 2000)
      return () => clearInterval(id)
    }
    const id = setInterval(nextInterval, 2000)
    return () => clearInterval(id)
  }, [goNext, translateX, isS])

  return (
    <div className="partners" id="partners">
      <div className="partners-title">
        Partners
        <div className="partners-arrows">
          <button type="button" onClick={onPrevClick} disabled={translateX === 0} className="partners-arrows-arrow">
            <Arrow />
          </button>
          <button
            type="button"
            onClick={onNextClick}
            disabled={isS ? translateX === -3080 : translateX === -2640}
            className="partners-arrows-arrow"
          >
            <Arrow />
          </button>
        </div>
      </div>
      <div className="partners-content">
        <div style={{ transform: `translateX(${translateX}px)` }} className="partners-content-list">
          {partners.map((item, index) => (
            <a
              href={item.link}
              target="_blank"
              rel="noreferrer"
              key={index}
              className={cn("partners-content-item", `item-${index}`)}
            >
              <img
                src={item.img}
                alt="partners image"
                className={cn("partners-content-item-img", {
                  white: item.isWhite,
                })}
              />
            </a>
          ))}
        </div>
      </div>
    </div>
  )
}
