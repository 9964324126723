import { useState } from "react"

import { ReactComponent as Bg } from "assets/icons/team-bg.svg"
import team1Src from "assets/images/team/1.webp"
import team2Src from "assets/images/team/2.webp"
import team3Src from "assets/images/team/3.webp"
import team5Src from "assets/images/team/5.webp"
import team6Src from "assets/images/team/6.webp"
import team7Src from "assets/images/team/7.webp"
import team8Src from "assets/images/team/8.webp"
import team9Src from "assets/images/team/9.webp"
import team10Src from "assets/images/team/10.webp"
import team11Src from "assets/images/team/11.webp"
import "./Team.scss"
import { Modal } from "../Modal"
import cn from "classnames"

export type ITeamItem = {
  title: string
  position: string
  desc: string
  img: string
}

export const Team = (): JSX.Element => {
  const [modal, setModal] = useState<ITeamItem | null>(null)
  const team: ITeamItem[] = [
    {
      title: "JACK FULTON",
      position: "Founder & CEO",
      desc: "Jack is a dynamic and results-oriented sports & entertainment executive with 20 years of experience in the combat sports industry. Fighters featured on ESPN, Eurosport, HBO, Showtime, and ESPN have all fought under Fulton’s promotions including Heavyweight Champions such as Shannon Briggs, Hasim Rahman, and Jameel McCline just to name a few. Jack is the Founder & CEO of International Championship Boxing LLC, the Founder & CEO of Fight Zone Fitness, and the Founder & CEO of Praetorian Media Group.",
      img: team1Src,
    },
    {
      title: "Terdema Ussery III",
      position: "COO & CIO",
      desc: "Terdema has been an active participant in the business community in both Dallas, TX, and Denver/Boulder, CO for 10+ years. Entrepreneurship has always been at the core of Terdema's professional career ever since he started his first company 10 years ago in the real estate sector. Since then, Terdema has founded companies or worked on initiatives in real estate, architecture, healthcare, food services, logistics, telecoms, media and entertainment, software & technology, as well as companies in the defense sector. Terdema has broad experience in every aspect of business from formation to corporate governance, ops, legal, biz dev, sales, and marketing.",
      img: team2Src,
    },
    {
      title: "SABIR MAGDEEV",
      position: "CPO",
      desc: "Sabir is a dynamic executive with more than 6 years of experience in blockchain, IT, and in between entertainment and blockchain industries. Sabir has founded Pink Unicorn Venture Studio which creates, develops, and invests in start-ups that grow to become stunning Unicorns. More than that, Sabir is the CEO of the project with a $20M company valuation and a minor partner at K&M Capital.",
      img: team5Src,
    },
    {
      title: "DEL WILBER",
      position: "Chief Strategy Officer",
      desc: "Del served as President and CEO at Celeritas Management Inc. and as CEO of MVPGROUP (an IPG company). Del has over 45 years of experience in the sports marketing business and is the President of The Perfect Game Foundation, a nonprofit organization specializing in assisting youth who are pursuing careers in the sports industry.",
      img: team6Src,
    },
    {
      title: "PAT CARMICHAEL",
      position: "Director of Advanced Technology",
      desc: "Pat Carmichael is CEO, Pres. of RT Ranger, Inc. with 37 years of experience in 3D application development and retired after 20 years as Manager of HKS, Inc. Advanced Technologies Group. RT Ranger is unmatched in using game engines as a base for high-fidelity real-time 3D environments to offer live, interactive first-person immersive virtual experiences like no other. In his own words, Pat has been putting the real into Unreal since 1998.",
      img: team11Src,
    },
    // {
    //   title: "SEAN KRAJEWSKI",
    //   position: "Co-Executive Producer & Director of Entertainment, Music & Animation",
    //   desc: "Sean has built his entrepreneurial career by investing in a diverse portfolio centered around two things: entertainment and celebrity. His first large exit was Mendocino farms – an upscale, fast-casual restaurant concept that he sold at 15 locations to private equity firm TPG. Since that point, Sean has focused on content production, releasing, or financing through his ventures projects in film, TV, and animation starring the likes of Matthew McConaughey, Anthony Mackie, and Laurence Fishburne. Krajewski’s other area of interest has been lifestyle and wellness. His venture studio is set to launch two health brands with Kenzie Taylor and Eva Mendes, and he has invested in a cannabis venture called Palms, which included a collaboration with Justin Bieber.",
    //   img: team3Src,
    // },
    {
      title: "Lisa M. Lum",
      position: "Director of Hospitality in the Metaverse",
      desc:
        "Lisa M. Lum is the Founder and CEO of Friends of the Metaverse™, an NFT membership-based project with a\n" +
        "mission to inspire the next generation of tech leaders. Lisa is also Principal of Buena Vista Vision, Inc., a\n" +
        "Metaverse and NFT development firm focused on luxury hospitality and urban real estate. Most recently,\n" +
        "Lisa served as Executive Director at Silicon Valley’s Asia America MultiTechnology Association (AAMA).\n" +
        "\n" +
        "Lisa previously oversaw operational development and planning for The Hongkong and Shanghai Hotels,\n" +
        "Ltd., owner and operator of The Peninsula Hotels.\n" +
        "Raised in San Francisco, Lisa received a B.A. in Legal Studies from U.C. Berkeley. Lisa attended the\n" +
        "Associate Kyoto Program at Doshisha University, completed the Venture Capital Executive Program at\n" +
        "Haas School of Business, and has a Certificate in Hotel Revenue Management from Cornell University.",
      img: team10Src,
    },
    // {
    //   title: "BRIAN BYRNE",
    //   position: "Director of Talent Media Relations",
    //   desc: "Brian is a former lead singer of multi-platinum selling Rockband I MotherEarth. Brian also has 20+ years of experience in entertainment, tech, and blockchain. He invests in multiple tech start-ups and advises them.",
    //   img: team4Src,
    // },
    {
      title: "ROB CORREA",
      position: "EVP Media & Properties",
      desc: "As the former Executive Vice President, Sports Programming at CBS and the former Executive Vice President of Entertainment & Sports at USA Network, Rob negotiated broadcast, cable and digital rights deals across the NFL, the Masters, PGA TOUR, PGA of America, US Open tennis, NASCAR and other motorsports, WWE, and boxing. Rob has developed and maintained long-standing relationships across the sports industry with league executives, sports marketers, and talent agents. He is a sports industry leader with over 25 years of programming, business, and production experience across broadcast and cable television.",
      img: team7Src,
    },
    {
      title: "DWAYNE LESLIE",
      position: "EVP Business Affairs",
      desc: "Dwayne is a mergers and acquisitions attorney, with a background that includes film financing. Dwayne's professional experience includes serving as a corporate and transactional lawyer at the global law firm Jones Day and Chief Operating Officer and General Counsel (Digital Media Division) for Geneva Films. He currently serves as Vice President & Chief Compliance Officer for Adventist Healthcare.",
      img: team8Src,
    },
    {
      title: "ANDREW COHEN",
      position: "EVP Marketing & Advertising",
      desc: "Andrew is the head of marketing and advertising for the ICB League. He began his career with the giants in corporate America, in sales for Procter & Gamble and marketing for Pepsico. He learned he had a flair for creative, and opened his own marketing agency, The A Team LLC which he has led for 20 years. This award-winning agency has worked with clients such as Jaguar Cars, Merck, Under Armour, Weight Watchers, Moet Hennessy USA, Voss Water, and many others.",
      img: team9Src,
    },
  ]
  return (
    <div className="team" id="team">
      <Bg className="team-bg" />
      <h2 className="team-title">Our Team</h2>
      <div className="team-content">
        {team.map((item, index) => (
          <div key={index} className={cn("team-content-item", `team-content-item-index${index}`)}>
            <div className="team-content-item-img-wrapper">
              <img src={item.img} alt="team image" className="team-content-item-img" />
            </div>
            <div className="team-content-item-title">{item.title}</div>
            <div className="team-content-item-position">{item.position}</div>
            <div className="team-content-item-desc">{item.desc}</div>
            <button className="team-content-item-btn" type="button" onClick={() => setModal(item)}>
              Show more
            </button>
          </div>
        ))}
      </div>
      <Modal isOpen={modal !== null} onClose={() => setModal(null)} item={modal} />
    </div>
  )
}
