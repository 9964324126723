import React from "react"

import pdfSrc from "assets/pdfs/black-diamond.pdf"

import "./BlackDiamond.scss"

export const BlackDiamond = (): JSX.Element => {
  return (
    <div className="black-diamond">
      <embed src={pdfSrc} />
    </div>
  )
}
